<template>
	<div>
		<b-row>
			<b-colxx xxs="12">
				<h1>USUARIOS</h1>
			</b-colxx>
			<b-colxx xxs="12">

				<b-card title="Detalle de usuario" v-if="bootstrapTable.selected.length>0">
			      		
					    <b-colxx xxs="12">
					        
				          <b-form @submit.prevent="onGridFormSubmit">
				            <b-row>

				              <b-colxx sm="12">
				                <b-form-group label="Nombre de usuario">
				                  <b-form-input type="text" v-model="selected.correo" />
				                </b-form-group>
				              </b-colxx>
				              <b-colxx sm="6">
				                <b-form-group label="Nombre">
				                  <b-form-input type="text" v-model="selected.nombre"/>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group label="Apellidos">
				                  <b-form-input v-model="selected.apellidos" ></b-form-input>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group label="Género">
				                  <b-form-input v-model="selected.genero" ></b-form-input>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group label="Teléfono">
				                  <b-form-input v-model="selected.telefono" ></b-form-input>
				                </b-form-group>
				              </b-colxx>
				              
				            </b-row>

				            <!--<b-button type="button" variant="primary" class="mt-4">{{ $t('forms.signin') }}</b-button>-->

				            <b-button type="button" variant="primary" class="mt-4" v-on:click="actualizaUsuario()">Guardar cambios</b-button>


				          </b-form>
					    </b-colxx>
  
			      	</b-card>
			      	<b-card title="Crear nuevo usuario" v-if="crearUsuario == true">
			      		
					    <b-colxx xxs="12">
					     	<p>Los usuarios creados aqui únicamente podrán visualizar tus dispositivos en el mapa.</p>
					     	<p>La contraseña ingresada servirá para que tus usuarios puedan acceder y ver tus dispositivos en el mapa.</p>
				          <b-form >
				            <b-row>

				              <b-colxx sm="6">
				                <b-form-group label="Nombre de usuario">
				                  <b-form-input type="text" v-model="newViewer.correo" />
				                </b-form-group>
				              </b-colxx>
				              <b-colxx sm="6">
				                <b-form-group label="Contraseña">
				                  <b-form-input type="password" v-model="newViewer.contrasena" />
				                </b-form-group>
				              </b-colxx>
				              <b-colxx sm="6">
				                <b-form-group label="Nombre">
				                  <b-form-input type="text" v-model="newViewer.nombre"/>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group label="Apellidos">
				                  <b-form-input v-model="newViewer.apellidos" ></b-form-input>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group label="Género">
				                  <b-form-select v-model="newViewer.genero" :options="opcionesGenero" ></b-form-select>
				                </b-form-group>
				              </b-colxx>

				              <b-colxx sm="6">
				                <b-form-group label="Teléfono">
				                  <b-form-input v-model="newViewer.telefono" ></b-form-input>
				                </b-form-group>
				              </b-colxx>
				              
				            </b-row>

				            <!--<b-button type="button" variant="primary" class="mt-4">{{ $t('forms.signin') }}</b-button>-->

				            <b-button type="button" variant="primary" class="mt-4" v-on:click="crearUsuarioViewer()" >Crear usuario</b-button>


				          </b-form>
					    </b-colxx>
  
			      	</b-card>
			      	<br>
				 <b-card class="mb-4" :title="$t('')">
				 	<div>
				 		<b-button v-if="crearUsuario == false" type="button" variant="primary" class="mt-4" v-on:click="formularioViewer();">Crear nuevo usuario</b-button>
				 	</div>
			        <b-table
			            ref="custom-table"
			            class="vuetable"
			            sort-by="title" sort-desc.sync="false"
			            @row-selected="rowSelected"
			            selectable
			            :select-mode="bootstrapTable.selectMode"
			            :current-page="currentPage"
			            selectedVariant="primary"
			            :fields="bootstrapTable.fields"
			            :items="dataArray"
			            :per-page="perPage"
			          >
			          <template slot="status" slot-scope="data">
			            <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
			          </template>
			        </b-table>
			        <b-pagination
			            size="sm"
			            align="center"
			            :total-rows="totalRows"
			            :per-page="perPage"
			            v-model="currentPage"
			          >
			            <template v-slot:next-text>
			              <i class="simple-icon-arrow-right"/>
			            </template>
			            <template v-slot:prev-text>
			              <i class="simple-icon-arrow-left"/>
			            </template>
			            <template v-slot:first-text>
			              <i class="simple-icon-control-start"/>
			            </template>
			            <template v-slot:last-text>
			              <i class="simple-icon-control-end"/>
			            </template>
			          </b-pagination>


			      	</b-card>
			      	
			</b-colxx>
		</b-row>
	</div>
</template>
<script>

import { 
	mapGetters,
	mapActions,
	requests
} from "vuex";

import axios from 'axios'

	export default{
		data(){
			return{
				currentPage: 1,
			    perPage: 5,
			    totalRows: 1,
			    bootstrapTable: {
			        selected: [],
			        selectMode: 'single',
			        fields: [
			          	{ key: 'correo', label: 'Usuario', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'nombre', label: 'Nombre', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'apellidos', label: 'Apellidos', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'genero', label: 'Género', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'telefono', label: 'Teléfono', sortable: true, tdClass: 'text-muted' }
			        ]
			    },
			    dataArray: [],
			    crearUsuario: false,
			    newViewer: {
			    	correo: "",
			    	contrasena: "",
			    	nombre: "",
			    	apellidos: "",
			    	genero: "",
			    	telefono:""
			    },
			    opcionesGenero: [{ value: null, text: 'Seleccione una opción',selected: true },
		          { value: 'Hombre', text: 'Hombre' },
		          { value: 'Mujer', text: 'Mujer' },
		          { value: 'Otro', text: 'Otro' }
		        ],
			}
		},

		computed: {
			/*totalRows(){
				return this.dataArray.length;
			},*/
			selected(){
				return this.bootstrapTable.selected[0];
				instance.crearUsuario = false;
			}
		},

		mounted: function(){
			this.getCuentas();
		},

		methods:{
			...mapActions(["getAll", "create", "update"]),

			getCuentas () {
		      	//const params = this.apiParamsConverter(ctx)
		      	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	
				var payload = {
					endpoint: "/api/usuario/cliente",
					token: token 
				}

				this.getAll(payload).then((response) => {
					console.log("EJECUTA FUNCION CUENTAS");
					instance.dataArray = response.data.data;
					instance.currentPage = 1;
					instance.totalRows = instance.dataArray.length;
				}).catch(_error => {
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
					instance.$toasted.error("La sesión caducó");
				});
		    },
		    apiParamsConverter (params) {
		      let apiParams = {}
		      if (params.perPage !== 0) {
		        apiParams.per_page = params.perPage
		      }
		      if (params.currentPage >= 1) {
		        apiParams.page = params.currentPage
		      }
		      if (params.sortBy && params.sortBy.length > 0) {
		        apiParams.sort = `${params.sortBy}|${params.sortDesc ? 'desc' : 'asc'}`
		      }
		      if (params.filter && params.filter.length > 0) {
		        // Optional
		      }
		      return apiParams
		    },
		    formularioViewer(){
		    	this.crearUsuario = !this.crearUsuario;
		    	this.bootstrapTable.selected = [];
		    },
		    crearUsuarioViewer(){
				var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	let payload = {
		      		endpoint: "/api/usuario",
		      		token: token,
		      		data: instance.newViewer
		      	};

		      	instance.$parent.showLoader("Enviando datos");

				this.create(payload).then((response) => {

					if(response.data.status === 200){
						//instance.dataArray = response.data.data;
						instance.currentPage = 1;
						
						instance.$toasted.success("Usuario creado correctamente");
						instance.newViewer = {
					    	correo: "",
					    	contrasena: "",
					    	nombre: "",
					    	apellidos: "",
					    	genero: "",
					    	telefono:""
					    };
					    instance.crearUsuario = false;
					    console.log("EJECUTA FUNCION");
						instance.getCuentas();
					}else{
						instance.$toasted.error(response.data.error);
					}

					instance.$parent.closeLoader();
				}).catch(_error => {
					instance.$parent.closeLoader();
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
					instance.$toasted.error(_error.msg);
				});
		    },

		    actualizaUsuario(){
		    	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	let payload = {
		      		endpoint: "/api/usuario",
		      		token: token,
		      		data: instance.newViewer
		      	};

		      	instance.$parent.showLoader("Enviando datos");

				this.update(payload).then((response) => {
					//instance.dataArray = response.data.data;
					instance.$toasted.success("Usuario creado correctamente");
					instance.$parent.closeLoader();
					instance.currentPage = 1;
				}).catch(_error => {
					instance.$parent.closeLoader();
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
					instance.$toasted.error(_error.msg)
				});
		    },
		    onPaginationData (paginationData) {
		      this.$refs.pagination.setPaginationData(paginationData)
		    },
		    onChangePage (page) {
		      this.$refs.vuetable.changePage(page)
		    },
		    rowSelected (items) {
		      this.bootstrapTable.selected = items
		    },
		}
	}
</script>